import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'

const MyAccount = () => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSocial, setIsSocial] = useState(false)
  const [status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [enDate, setEnDate] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setName(userName)
      setEmail(userEmail)
      setIsSocial(userObject.isSocial)
      setStatus(userObject.status)
      setStartDate(userObject.startDate)
      setEnDate(userObject.enDate)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      //setLoggedIn(true)
    }
  }, [])
  useEffect(() => {
    updateuserData()
  }, [])

  const updateuserData = () => {
    commonService
      .CommonService('userdata', {})
      .then(async (res) => {
        if (res.success == true) {
          if (res.user.verified) {
            window.localStorage.setItem('isLoggedIn', true)
            window.localStorage.setItem('token', res.token)
            window.localStorage.setItem('user', JSON.stringify(res.user))
            const userObject = res.user

            // Access the email property
            const userName = userObject.name
            const userEmail = userObject.email

            setName(userName)
            setEmail(userEmail)
            setIsSocial(userObject.isSocial)
            setStatus(userObject.status)
            setStartDate(userObject.startDate)
            setEnDate(userObject.enDate)
          }
        } else {
        }
      })
      .catch((err) => {})
      .finally(() => {})
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value)
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault()
    // Perform password change logic here
  }
  const onLogout = () => {
    window.localStorage.setItem('isLoggedIn', false)
    window.localStorage.setItem('token', '')
    window.localStorage.setItem('user', null)
    navigate('/')

    window.location.reload()
  }

  const manageSubscription = (e) => {
    e.preventDefault()
    const customerPortalUrl = 'https://app.lemonsqueezy.com/my-orders'
    window.open(customerPortalUrl, '_blank')
  }

  const handleSubmit = (e) => {
    // Prevent the default submit and page reload
    e.preventDefault()
    if (confirmPassword != newPassword) {
      toast.success('Confirm Password should match newpassword', {
        type: 'error',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      })
      return
    }
    setLoading(true)

    commonService
      .CommonService('change', {
        newPassword: confirmPassword,
        oldPassword: password,
      })
      .then(async (res) => {
        if (res.success == true) {
          onLogout()
          toast.success(res.message, {
            type: 'success',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        } else {
          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Wrapper>
      <Header>My Account</Header>
      <Section>
        <SectionTitle>User Information</SectionTitle>
        <UserInfo>
          <Label>Name:</Label>
          <Value>{name}</Value>
          <Label>Email:</Label>
          <Value>{email}</Value>
        </UserInfo>
      </Section>
      <Button onClick={onLogout}>Logout</Button>
      {status === 'active' && (
        <Section>
          <SectionTitle>Subscription Details</SectionTitle>
          <SubscriptionInfo>
            <Label>Plan:</Label>
            <Value>Premium</Value>
            <Label>Renewal Date:</Label>
            <Value>{enDate}</Value>
          </SubscriptionInfo>
        </Section>
      )}
      {status === 'activecl' && (
        <Section>
          <SectionTitle>Subscription Details</SectionTitle>
          <SubscriptionInfo>
            <Label>Plan:</Label>
            <Value>Basic Plus</Value>
            <Label>Renewal Date:</Label>
            <Value>{enDate}</Value>
          </SubscriptionInfo>
        </Section>
      )}
      {status === 'active' || status === 'activecl' ? (
        <div>
          <div>
            To cancel a subscription, send an email to eversolutions24@gmail.com
            with the subject 'Cancel My Subscription' from your registered email
            address. We will process the subscription cancellation within 24
            hours. Thank you.
          </div>
          <Button onClick={manageSubscription}>Manage Subscription</Button>
        </div>
      ) : (
        <div style={{ marginTop: '30px' }}>
          If your subscription information is not updated here after
          subscription, kindly log out and log in again to refresh the data.
        </div>
      )}
      {!isSocial && (
        <Section>
          <SectionTitle>Change Password</SectionTitle>
          <PasswordForm onSubmit={handleSubmit}>
            <Label>Current Password:</Label>
            <Input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Label>New Password:</Label>
            <Input
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <Label>Confirm New Password:</Label>
            <Input
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <Button type="submit">
              {loading && (
                <div className="loader">
                  <PropagateLoader size={10} color="#fff" />
                </div>
              )}
              {!loading && 'Change Password'}
            </Button>
          </PasswordForm>
        </Section>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Header = styled.h1`
  font-size: 24px;
  color: #ff6e7f;
  margin-bottom: 20px;
`

const Section = styled.div`
  margin-bottom: 30px;
  margin-top: 60px;
`

const SectionTitle = styled.h2`
  font-size: 18px;
  color: #ff6e7f;
  margin-bottom: 10px;
`

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

const SubscriptionInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

const Label = styled.span`
  font-weight: bold;
`

const Value = styled.span`
  color: #666;
`

const PasswordForm = styled.form`
  display: grid;
  grid-gap: 10px;
`

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`

const Button = styled.button`
  padding: 8px 16px;
  background-color: #ff6e7f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
  &:hover {
    background-color: #e65c6f;
  }
`

export default MyAccount
