import React from 'react'
import './LoginScreen.css'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import GoogleIcon from '@mui/icons-material/Google'

const LoginScreen = () => {
  const navigate = useNavigate()

  useEffect(() => {}, [])
  const googlelogin = useGoogleLogin({
    onNonOAuthError: () => {
      console.log('userInfo')
    },
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse)
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data)

      console.log(userInfo)
      handleGoogleSubmit(userInfo.name, userInfo.email)
    },
    onError: () => {
      console.log('userInfo')
    },
    flow: 'implicit', // implicit is the default
  })

  const handleGoogleSubmit = (name, email) => {
    setLoading(true)

    commonService
      .apiService('social', { name, email })
      .then(async (res) => {
        if (res.success == true) {
          window.localStorage.setItem('isLoggedIn', true)
          window.localStorage.setItem('token', res.token)
          window.localStorage.setItem('user', JSON.stringify(res.user))

          toast.success(res.message, {
            type: 'success',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
          navigate('/')

          window.location.reload()
        } else {
          localStorage.setItem('isLoggedIn', false)

          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        localStorage.setItem('isLoggedIn', false)

        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleLogin = (e) => {
    // Prevent the default submit and page reload
    e.preventDefault()
    setLoading(true)

    commonService
      .loginService({ email, password })
      .then(async (res) => {
        if (res.success == true) {
          if (res.user.verified) {
            window.localStorage.setItem('isLoggedIn', true)
            window.localStorage.setItem('token', res.token)
            window.localStorage.setItem('user', JSON.stringify(res.user))
            toast.success(res.message, {
              type: 'success',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            navigate('/')

            window.location.reload()
          } else {
            toast.success(
              'You have not verified your email yet. Please verify it now',
              {
                type: 'warning',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              },
            )
            navigate('/verify')
          }
        } else {
          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState()
  const [password, setPassword] = useState('')

  return (
    <div className="login-wrapper">
      <div className="login-screen">
        <div className="login-container">
          <h1 className="login-title">Welcome to ResumeAnalyzer</h1>
          <div className="login-content">
            <form onSubmit={handleLogin}>
              <div className="input-field">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input-text"
                  placeholder="Email"
                />
              </div>
              <div className="input-field">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-text"
                  placeholder="Password"
                />
              </div>
              <button type="submit" className="login-btn">
                {loading && (
                  <div className="loader">
                    <PropagateLoader size={10} color="#fff" />
                  </div>
                )}
                {!loading && 'Log In'}
              </button>
              <div className="forgot-password">
                <Link to="/forgotpassword" className="forgot-password-link">
                  Forgot Password?
                </Link>
              </div>
            </form>
            <div className="separator">
              <span>or</span>
            </div>
            <button className="google-login-btn" onClick={googlelogin}>
              <GoogleIcon className="google-icon" /> Sign in with Google
            </button>
          </div>
        </div>
        <div className="signup-prompt">
          <span>Don't have an account?</span>
          <Link to="/register" className="signup-link">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen
