import React from 'react'
import './common.css' // Assuming you have a CSS file for styling
const Terms = () => {
  return (
    <div className="dark-theme">
      <h1>Terms and Conditions</h1>
      <p>Welcome to resumeanalyser.com!</p>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of Resume Analyser's Website, located at www.resumeanalyser.com.
      </p>
      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use resumeanalyser.com if you do not
        agree to take all of the terms and conditions stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement, and Disclaimer Notice and all Agreements: "User", "You", and
        "Your" refer to you, the person accessing this website and accepting the
        Company's terms and conditions. "The Company", "Ourselves", "We", "Our",
        and "Us" refer to Resume Analyser. "Party", "Parties", or "Us", refers
        to both the User and ourselves. All terms refer to the offer,
        acceptance, and consideration of payment necessary to undertake the
        process of our assistance to the User in the most appropriate manner for
        the express purpose of meeting the User's needs in respect to the
        provision of the Company's stated services, in accordance with and
        subject to, prevailing law. Any use of the above terminology or other
        words in the singular, plural, capitalization, and/or he/she or they are
        taken as interchangeable and therefore as referring to the same.
      </p>
      <h2>Cookies</h2>
      <p>
        We employ the use of cookies. By accessing resumeanalyser.com, you agree
        to use cookies in agreement with Resume Analyser's Privacy Policy.
      </p>
      <p>
        Most interactive websites use cookies to enable the functionality of
        certain areas to make it easier for people visiting our website. Some of
        our affiliate/advertising partners may also use cookies.
      </p>
      <h2>License</h2>
      <p>
        Unless otherwise stated, Resume Analyser and/or its licensors own the
        intellectual property rights for all material on resumeanalyser.com. All
        intellectual property rights are reserved. You may access this from
        resumeanalyser.com for your own personal use, subject to restrictions
        set in these terms and conditions.
      </p>
      <p>You must not:</p>
      <ul>
        <li>Republish material from resumeanalyser.com</li>
        <li>Sell, rent, or sub-license material from resumeanalyser.com</li>
        <li>Reproduce, duplicate, or copy material from resumeanalyser.com</li>
        <li>Redistribute content from resumeanalyser.com</li>
      </ul>
      <h2>Content Liability</h2>
      <p>
        We shall not be held responsible for any content that appears on your
        Website. You agree to protect and defend us against all claims that
        arise on your Website. No link(s) should appear on any Website that may
        be interpreted as libelous, obscene, or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of
        any third party rights.
      </p>
      <h2>Reservation of Rights</h2>
      <p>
        We reserve the right to request that you remove all links or any
        particular link to our Website. You agree to immediately remove all
        links to our Website upon request. We also reserve the right to amend
        these terms and conditions and its linking policy at any time. By
        continuously linking to our Website, you agree to be bound to and follow
        these linking terms and conditions.
      </p>
      <h2>Removal of links from our website</h2>
      <p>
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us at any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </p>
      <p>
        We do not ensure that the information on this website is correct; we do
        not warrant its completeness or accuracy; nor do we promise to ensure
        that the website remains available or that the material on the website
        is kept up to date.
      </p>
      <h2>Refund Policy</h2>
      <p>
        All purchases of our plans and services on resumeanalyser.com are
        non-refundable.
      </p>
      <p>
        Once you have made a purchase and the payment has been processed, no
        refunds will be provided, regardless of the reason for cancellation or
        termination of your account or subscription.
      </p>
      <p>
        We may, at our sole discretion, consider refund requests in exceptional
        circumstances, but such requests are not guaranteed to be granted.
      </p>

      <h2>Disclaimer</h2>
      <p>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties, and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>
      <ul>
        <li>
          Limit or exclude our or your liability for death or personal injury;
        </li>
        <li>
          Limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          Limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li>
          Exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
      </ul>
      <p>
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort, and for breach of
        statutory duty.
      </p>
      <p>
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </p>
    </div>
  )
}

export default Terms
