import React, { useState, useEffect, useRef } from 'react'
import './RecruiterDashboard.css'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import { Menu, MenuItem } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import SpeedIcon from '@mui/icons-material/Speed'
import Delete from '@mui/icons-material/Delete'
import BarLoader from 'react-spinners/BarLoader'
import ScaleLoader from 'react-spinners/ScaleLoader'
import LoadingBar from 'react-top-loading-bar'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import ReactSpeedometer from 'react-d3-speedometer'
import { useNavigate, useParams } from 'react-router-dom'

const RecruiterDashboard = () => {
  const navigate = useNavigate()
  const { jobId } = useParams()
  const [showAddJobDialog, setShowAddJobDialog] = useState(false)
  const [jobName, setJobName] = useState('')
  const [jobDescription, setJobDescription] = useState('')
  const [jobListings, setJobListings] = useState([])
  const [resumelist, setResumeList] = useState([])
  const ref = useRef(null)
  const [currentResumeID, setCurrentResumeID] = useState(null)
  const [selectedJobListing, setSelectedJobListing] = useState(null)
  const [showResumeUploadDialog, setShowResumeUploadDialog] = useState(false)
  const [uploadedResumes, setUploadedResumes] = useState([])
  const [loading, setLoading] = useState(false)
  const [desLoading, setDesLoading] = useState(false)
  const [resumeLoading, setResumeLoading] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSocial, setIsSocial] = useState(false)
  const [status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [enDate, setEnDate] = useState('')
  const [progress, setProgress] = useState(0)
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setName(userName)
      setEmail(userEmail)
      setIsSocial(userObject.isSocial)
      setStatus(userObject.status)
      setStartDate(userObject.startDate)
      setEnDate(userObject.enDate)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      console.log('++++++++++++++++ storedValue', storedValue)

      setLoggedIn(true)
    }
  }, [])
  console.log('upload event')
  const handleAddJobClick = () => {
    if (loggedIn) {
      if (status === 'active') {
        setShowAddJobDialog(true)
      } else {
        toast.success(
          'To access this feature, please subscribe to the premium plan',
          {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          },
        )
      }
    } else {
      toast.success(
        "To access this feature, please log in to your account. If you don't have an account yet, you can register one ",
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }

  const handleJobNameChange = (e) => {
    setJobName(e.target.value)
  }

  const handleJobDescriptionChange = (e) => {
    setJobDescription(e.target.value)
  }
  const handleAddResumeClick = () => {
    console.log('upload event')

    setShowResumeUploadDialog(true)
  }

  const [sortedResumelist, setSortedResumelist] = useState([])
  const [sortingEnabled, setSortingEnabled] = useState(false)
  useEffect(() => {
    if (sortingEnabled) {
      // Sort resumes based on score value
      const sortedResumes = [...resumelist].sort((a, b) => b.score - a.score)
      console.log('sortedResumes', sortedResumes)

      setSortedResumelist(sortedResumes)
    } else {
      // Sorting is disabled, set resumes without sorting
      setSortedResumelist(resumelist)
    }
  }, [resumelist, sortingEnabled])

  const toggleSorting = () => {
    setSortingEnabled(!sortingEnabled)
  }

  const handleResumeUpload = (event) => {
    if (selectedJobListing === 'defaultJobId') {
      toast.success('Job Description not selected to analyse resume', {
        type: 'error',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      })
    } else {
      console.log('upload event' + event)
      // const files = Array.from(event.target.files)
      analyseResume(event.target.files[0], selectedJobListing)
    }
    setShowResumeUploadDialog(false)
  }
  const handleAddJobDescription = () => {
    addDesc(jobName, jobDescription)
    setShowAddJobDialog(false)
    setJobName('')
    setJobDescription('')
  }

  const handleJobListingClick = (jobListing) => {
    setSelectedJobListing(jobListing.jobID)
    navigate(`/recruiter/${jobListing.jobID}`)
    //getResumeList(jobListing.jobID)
  }

  const handleDeleteClick = (jobId) => {
    deleteDesc(jobId)
  }

  const handleMenuOpen = (event, resumeID) => {
    setCurrentResumeID(resumeID)
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteResume = () => {
    if (currentResumeID !== null) {
      deleteResume(currentResumeID)
    }
    handleMenuClose()
  }

  useEffect(() => {
    // Check if jobId exists
    if (jobId && jobId !== 'defaultJobId') {
      setSelectedJobListing(jobId)
      getResumeList(jobId)
    }
  }, [jobId])
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  useEffect(() => {
    let isSubscribed = true

    const fetchData = async () => {
      if (isSubscribed) {
        // Call getDescList function here
        getDescList()
        console.log('++++++++++++++++ getDescList()')
      }
    }

    fetchData()

    return () => {
      isSubscribed = false
    }
  }, [])
  const getDescList = () => {
    setDesLoading(true)
    commonService
      .CommonService('descriptions', {})
      .then(async (res) => {
        if (res.success == true) {
          setJobListings(res.descriptions)
        } else {
          if (
            res.message === 'invalid token' ||
            res.message === 'invalid user'
          ) {
            console.log('loggedIn+++++', loggedIn)
            if (localStorage.getItem('isLoggedIn')) {
              toast.success('Session Expired. Please login again', {
                type: 'error',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              })
              navigate('/login')
            } else {
              toast.success(
                "To access this feature, please log in to your account. If you don't have an account yet, you can register one ",
                {
                  type: 'error',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                },
              )
            }

            // reject(new Error('Session Expired. Please login again'))
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
          }
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setDesLoading(false)
      })
  }

  const getResumeList = (id) => {
    setResumeLoading(true)
    setResumeList([])
    commonService
      .CommonServiceGet('resumelist', id)
      .then(async (res) => {
        if (res.success == true) {
          setResumeList(res.resumes)
        } else {
          if (
            res.message === 'invalid token' ||
            res.message === 'invalid user'
          ) {
            toast.success('Session Expired. Please login again', {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // onLogout()
            navigate('/login')
            // reject(new Error('Session Expired. Please login again'))
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
          }
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setResumeLoading(false)
      })
  }
  const deleteResume = (resumeId) => {
    setLoading(true)

    commonService
      .CommonService('deleteresume', { resumeId })
      .then(async (res) => {
        if (res.success == true) {
          getResumeList(selectedJobListing)
        } else {
          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const deleteDesc = (jobId) => {
    setLoading(true)

    commonService
      .CommonService('deletejob', { jobId })
      .then(async (res) => {
        if (res.success == true) {
          getDescList()
        } else {
          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const addDesc = (jobname, jobDesc) => {
    setLoading(true)

    commonService
      .CommonService('adddesc', { jobname, jobDesc })
      .then(async (res) => {
        if (res.success == true) {
          getDescList()
        } else {
          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const analyseResume = async (files, id) => {
    let formData = new FormData() // formdata object

    formData.append('jobId', id)
    formData.append('files', files)

    console.log(formData)

    setLoading(true)
    loadingStart()
    commonService
      .diagram('analyse', formData)
      .then(async (res) => {
        if (res.success === true) {
          getResumeList(selectedJobListing)
        } else {
          if (
            res.message === 'invalid token' ||
            res.message === 'invalid user'
          ) {
            toast.success('Session Expired. Please login again', {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // onLogout()
            navigate('/login')
            // reject(new Error('Session Expired. Please login again'))
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // reject(new Error(res.message))
          }
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! Please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
        loadingStop()
      })
  }

  function loadingStart() {
    ref.current.continuousStart()
    setLoading(true)
  }
  function loadingStop() {
    setLoading(false)
    try {
      ref.current.complete()
    } catch (err) {}
  }
  return (
    <div className="recruiter-dashboard">
      <LoadingBar color="#FF2D60" ref={ref} />

      <div className="sidebar">
        <button
          className="add-job-btn add-job-description-btn"
          onClick={handleAddJobClick}
        >
          Add Job Description
        </button>
        <BarLoader
          color="#ff6e7f"
          loading={desLoading}
          cssOverride={{ width: 'auto' }}
        />
        {jobListings.map((jobListing, index) => (
          <div
            key={index}
            className={`job-listing ${
              selectedJobListing === jobListing.jobID ? 'selected' : ''
            }`}
            onClick={() => handleJobListingClick(jobListing)}
          >
            {jobListing.name}
            <span
              className="delete-icon"
              onClick={(e) => {
                e.stopPropagation() // Prevent the click event from bubbling up to the parent div
                handleDeleteClick(jobListing.jobID)
              }}
            >
              {/* Add your delete material icon here */}
              {/* Example: <DeleteIcon /> */}
              <Delete />
            </span>
          </div>
        ))}
      </div>
      <div className="main-content">
        {resumeLoading && (
          <div className="loader-container">
            <ScaleLoader loading={resumeLoading} color="#ff6e7f" />
          </div>
        )}
        {selectedJobListing ? (
          <div>
            <div className="content-header">
              {selectedJobListing && (
                <div>
                  <button
                    className="add-resume-btn"
                    onClick={toggleSorting}
                    variant="contained"
                    color="primary"
                  >
                    {sortingEnabled ? 'Disable Sorting' : 'SortBy Score'}
                  </button>
                  <button
                    className="add-resume-btn add-resume-btn-border"
                    onClick={handleAddResumeClick}
                  >
                    + Add Resume
                  </button>
                </div>
              )}
            </div>
            {selectedJobListing && (
              <div className="resume-cards">
                {sortedResumelist.map((resume, index) => (
                  <Card
                    key={resume.resumeID}
                    sx={{ maxWidth: 345 }}
                    elevation={6}
                  >
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                          {(resume?.name || '').slice(0, 1)}
                        </Avatar>
                      }
                      action={
                        <div>
                          <IconButton
                            aria-label="settings"
                            onClick={(event) =>
                              handleMenuOpen(event, resume.resumeID)
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() =>
                                handleDeleteResume(resume.resumeID)
                              }
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      }
                      title={resume.name}
                      titleTypographyProps={{ variant: 'h5' }} // Increase font size of title
                      subheader={
                        <div>
                          <Typography variant="body2" color="text.secondary">
                            {resume.phone}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {resume.email}
                          </Typography>
                        </div>
                      }
                    />
                    {/* <CardMedia
                      component="img"
                      height="194"
                      image="/static/images/cards/paella.jpg"
                      alt="Paella dish"
                    /> */}
                    <CardContent>
                      <ReactSpeedometer
                        value={resume.score}
                        needleColor="steelblue"
                        needleTransitionDuration={4000}
                        needleTransition="easeElastic"
                        customSegmentStops={[0, 20, 40, 60, 80, 100]}
                        currentValueText={`${resume.score} / 100`}
                        paddingHorizontal={44}
                        paddingVertical={44}
                        valueTextFontSize={'40px'}
                        maxValue={100}
                        width={240}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {resume.analysis}
                      </Typography>
                    </CardContent>
                    {/* <CardActions disableSpacing>
                      <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton aria-label="share">
                        <SpeedIcon />
                      </IconButton>
                    </CardActions> */}
                  </Card>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="welcome-message">
            <h1 className="main-heading">
              <span className="gradient-text">
                AI-Driven Resume Screening, Find Talents Effortlessly
              </span>
            </h1>{' '}
            <p className="sub-heading">
              Streamline your recruitment process with our AI-powered resume
              analysis.
            </p>
            <button
              className="add-job-description-btn"
              onClick={handleAddJobClick}
            >
              Add Job Description
            </button>
          </div>
        )}
      </div>
      <Dialog
        open={showAddJobDialog}
        onClose={() => setShowAddJobDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="dialog-title">Add Job Description</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <TextField
                autoFocus
                margin="dense"
                label="Job Name"
                fullWidth
                value={jobName}
                onChange={handleJobNameChange}
                inputProps={{ style: { fontSize: '1.2rem' } }}
              />
              <TextField
                margin="dense"
                label="Job Description"
                fullWidth
                multiline
                rows={6}
                value={jobDescription}
                onChange={handleJobDescriptionChange}
                inputProps={{ style: { fontSize: '1.2rem' } }}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            className=" add-job-description-btn"
            onClick={() => setShowAddJobDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className=" add-job-description-btn"
            onClick={handleAddJobDescription}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showResumeUploadDialog}
        onClose={() => setShowResumeUploadDialog(false)}
      >
        <DialogTitle>Upload Resume</DialogTitle>
        <DialogContent>
          <div className="drag-drop-area">
            <CloudUploadIcon fontSize="large" />
            <span className="upload-icon">&#128194;</span>
            <p>Drag and drop your resume here, or click to upload</p>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleResumeUpload}
              style={{ display: 'none' }}
              id="resume-input"
            />
            <label htmlFor="resume-input" className="upload-btn">
              Upload Resume
            </label>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RecruiterDashboard
