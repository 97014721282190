import React, { useState, useEffect, useRef } from 'react'
import styles from './JobSeekerDashboard.module.css' // Import CSS moduleimport pdfToText from 'react-pdftotext'
import { useNavigate } from 'react-router-dom'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'
import BarLoader from 'react-spinners/BarLoader'
import ReactSpeedometer from 'react-d3-speedometer'
import LoadingBar from 'react-top-loading-bar'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import DownloadIcon from '@mui/icons-material/Download'
import ReplayIcon from '@mui/icons-material/Replay'
import { IconButton } from '@mui/material'
const JobSeekerDashboard = () => {
  const navigate = useNavigate()

  const [resume, setResume] = useState(null)
  const [jobDescription, setJobDescription] = useState('')
  const [analyzedResumeData, setAnalyzedResumeData] = useState(null)
  const [analyzedResumeScore, setAnalyzedResumeScore] = useState(null)
  const [coverletterData, setcoverletterData] = useState(null)
  const ref = useRef(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSocial, setIsSocial] = useState(false)
  const [status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [enDate, setEnDate] = useState('')
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    // Load or import the CSS file dynamically when the component mounts
    const link = document.createElement('link')
    link.href = './RecruiterDashboard.css' // Adjust the path as per your project structure
    link.rel = 'stylesheet'
    document.head.appendChild(link)

    // Cleanup function to remove the CSS file when the component unmounts
    return () => {
      document.head.removeChild(link)
    }
  }, [])

  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setName(userName)
      setEmail(userEmail)
      setIsSocial(userObject.isSocial)
      setStatus(userObject.status)
      setStartDate(userObject.startDate)
      setEnDate(userObject.enDate)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      setLoggedIn(true)
    }
  }, [])

  const downloadPdfDocument = async () => {
    if (status === 'active' || status === 'activecl') {
      const input = document.getElementById('coverletter')

      // Use html2canvas to capture the HTML element as a canvas.
      const canvas = await html2canvas(input, {
        scale: 3, // Set the scale for better quality.
      })

      // Get the width and height of the canvas.
      const imgWidth = canvas.width
      const imgHeight = canvas.height

      // Create a new jsPDF instance with a custom page size based on the canvas size.
      const pdf = new jsPDF({
        orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
        unit: 'px',
        format: [imgWidth, imgHeight], // Set the page size based on the canvas size.
      })

      // Convert the canvas to data URL format.
      const imgData = canvas.toDataURL('image/png')

      // Add the image to the PDF document.
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight)

      // Save the PDF document with the specified filename.
      pdf.save(`coverletter.pdf`)
    } else {
      toast.success(
        'To access this feature, please subscribe to the premium plan',
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }
  const handleResumeUpload = (event) => {
    if (loggedIn) {
      const file = event.target.files[0]
      setResume(file)
    } else {
      toast.success(
        "To access this feature, please log in to your account. If you don't have an account yet, you can register one ",
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }

  const handleJobDescriptionChange = (event) => {
    setJobDescription(event.target.value)
  }

  const handleAnalyzeResume = () => {
    setProgress(30)

    setTimeout(() => {
      setProgress(70)
    }, 1000)

    setTimeout(() => {
      setProgress(100)
    }, 2000)
    analyseResume()
  }

  const handleRemoveResume = () => {
    setResume(null)
    setAnalyzedResumeData(null)
    setAnalyzedResumeScore(null)
  }
  const analyseResume = async () => {
    let formData = new FormData() // formdata object
    console.log('file type', resume) // true

    formData.append('files', resume)
    formData.append('jobDes', jobDescription)

    console.log(formData)
    ref.current.continuousStart()
    setLoading(true)
    commonService
      .diagram('resumeanalyse', formData)
      .then(async (res) => {
        if (res.success === true) {
          setAnalyzedResumeData(res.message.analysis)
          setAnalyzedResumeScore(res.message.score)
        } else {
          if (
            res.message === 'invalid token' ||
            res.message === 'invalid user'
          ) {
            toast.success('Session Expired. Please login again', {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // onLogout()
            navigate('/login')
            // reject(new Error('Session Expired. Please login again'))
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // reject(new Error(res.message))
          }
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! Please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
        try {
          ref.current.complete()
        } catch (err) {}
      })
  }

  const coverletter = async () => {
    let formData = new FormData() // formdata object
    console.log('file type', resume) // true

    formData.append('files', resume)
    formData.append('jobDes', jobDescription)

    console.log(formData)
    ref.current.continuousStart()
    setLoading(true)
    commonService
      .diagram('coverletter', formData)
      .then(async (res) => {
        if (res.success === true) {
          setcoverletterData(res.message.coverLetter)
        } else {
          if (
            res.message === 'invalid token' ||
            res.message === 'invalid user'
          ) {
            toast.success('Session Expired. Please login again', {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // onLogout()
            navigate('/login')
            // reject(new Error('Session Expired. Please login again'))
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            // reject(new Error(res.message))
          }
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! Please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
        try {
          ref.current.complete()
        } catch (err) {}
      })
  }
  return (
    <div className={styles['job-seeker-dashboard']}>
      {' '}
      <div className={styles.header}>
        <h1 className={styles['main-heading']}>
          <span className={styles['gradient-text']}>
            Free ATS Score & Cover Letter Tool
          </span>
        </h1>
        <p className={styles['sub-heading']}>
          Enhance your job application with our intelligent resume analysis and
          cover letter generation tool
        </p>
      </div>
      <div className={styles['main-content']}>
        {' '}
        {/* Use styles object */}
        <LoadingBar color="#FF2D60" ref={ref} />
        <div className={styles['resume-upload']}>
          {' '}
          {/* Use styles object */}
          {!resume ? (
            <label htmlFor="resume-input" className={styles['upload-btn']}>
              {' '}
              {/* Use styles object */}+ Upload Resume
            </label>
          ) : (
            <div className={styles['uploaded-resume']}>
              {' '}
              {/* Use styles object */}
              <span>{resume.name}</span>
              <i className={styles['delete-icon']} onClick={handleRemoveResume}>
                {' '}
                {/* Use styles object */}
                &#10005;
              </i>
            </div>
          )}
          <input
            id="resume-input"
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleResumeUpload}
            style={{ display: 'none' }}
          />
        </div>
        <textarea
          className={styles['job-description']}
          placeholder="Enter job description..."
          value={jobDescription}
          onChange={handleJobDescriptionChange}
        />
        <div className={styles['action-buttons']}>
          <button
            className={`${styles['analyze-btn']} ${styles['add-job-description-btn']}`}
            onClick={handleAnalyzeResume}
            disabled={!resume}
          >
            ATS Score
          </button>
          <button
            className={`${styles['cover-letter-btn']} ${styles['add-job-description-btn']}`}
            disabled={!resume}
            onClick={coverletter}
          >
            Create Cover Letter
          </button>
        </div>
        <div></div>
        {analyzedResumeData && resume && (
          <div className={styles['analyzed-resume-data']}>
            <h3>Analyzed Resume ATS Score</h3>
            <ReactSpeedometer
              value={analyzedResumeScore}
              needleColor="steelblue"
              needleTransitionDuration={4000}
              needleTransition="easeElastic"
              customSegmentStops={[0, 20, 40, 60, 80, 100]}
              currentValueText={`${analyzedResumeScore} / 100`}
              paddingHorizontal={44}
              paddingVertical={44}
              valueTextFontSize={'60px'}
              maxValue={100}
              height={300}
              width={400}
              style={{ height: '350px !important' }} // Set height using inline style
            />
            <div
              style={{
                fontSize: '60px',
                fontWeight: 'bold',
                fill: 'rgb(102, 102, 102)',
              }}
            >
              {`${analyzedResumeScore} / 100`}
            </div>
            <div>{analyzedResumeData}</div>
          </div>
        )}
        {coverletterData && resume && (
          <div>
            <div className={styles['download-area']}>
              <IconButton
                className={`${styles['cover-letter-download-btn']} ${styles['add-job-description-btn']}`}
                disabled={!resume}
                onClick={downloadPdfDocument}
                title="Download"
                sx={{ color: '#a0d8ff' }} // Set the icon color using sx prop
              >
                <DownloadIcon />
              </IconButton>

              <IconButton
                className={`${styles['cover-letter-download-btn']} ${styles['add-job-description-btn']}`}
                disabled={!resume}
                onClick={coverletter}
                title="Regenerate"
                sx={{ color: '#a0d8ff' }} // Set the icon color using sx prop
              >
                <ReplayIcon />
              </IconButton>
            </div>
            <div id="coverletter" className={styles['analyzed-resume-data']}>
              <h3>Cover Letter</h3>
              <div dangerouslySetInnerHTML={{ __html: coverletterData }}></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default JobSeekerDashboard
