import React, { useState } from 'react'
import './App.css'
import LandingPage from './pages/LandingPage'
import AppLayout from './pages/AppLayout'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  BrowserRouter as Router,
  Routes,
  HashRouter,
  Route,
  useLocation,
} from 'react-router-dom'
import Navbar from './pages/Navbar'
import { Helmet } from 'react-helmet'

const App = () => {
  const [jobDescription, setJobDescription] = useState('')
  const [resumes, setResumes] = useState([])

  const handleJobDescriptionChange = (e) => {
    setJobDescription(e.target.value)
  }

  const handleResumeUpload = (e) => {
    const files = Array.from(e.target.files)
    setResumes([...resumes, ...files])
  }

  const analyzeResume = (resume) => {
    // Add your logic to analyze the resume against the job description
    console.log('Analyzing resume:', resume.name)
  }

  return (
    <div>
      <Helmet>
        <title>
          Resume Analyzer: AI-Powered Resume Analysis and Free Cover Letter
          Generation
        </title>
        <meta
          name="description"
          content="Resume Analyzer: AI-powered tool for job seekers and recruiters. Analyze resumes against job descriptions, generate free cover letters, and receive detailed analysis reports and ATS scores. Streamline your hiring process or job search with resumeanalyser.com."
        />
      </Helmet>
      <GoogleOAuthProvider clientId="322527070396-o9qn46enj64i6mtf0e0tfih46se0v5e4.apps.googleusercontent.com">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <HashRouter>
          <AppLayout />
        </HashRouter>
      </GoogleOAuthProvider>
    </div>
  )
}

export default App
