import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './LandingPage.css'
import JobSeekerDashboard from './JobSeekerDashboard'
import videobg from '../assets/videobg.mp4'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const LandingPage = () => {
  const navigate = useNavigate()

  const [isJobSeeker, setIsJobSeeker] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSocial, setIsSocial] = useState(false)
  const [status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [enDate, setEnDate] = useState('')
  const [progress, setProgress] = useState(0)
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setName(userName)
      setEmail(userEmail)
      setIsSocial(userObject.isSocial)
      setStatus(userObject.status)
      setStartDate(userObject.startDate)
      setEnDate(userObject.enDate)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      console.log('++++++++++++++++ storedValue', storedValue)

      setLoggedIn(true)
    }
  }, [])
  useEffect(() => {
    // Manually initialize Lemon.js after the component has mounted
    window.createLemonSqueezy()
  }, [])
  const handleBuy = () => {
    if (localStorage.getItem('isLoggedIn')) {
      const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/748b2fcc-f31d-4628-b3fd-9723399bad3c?checkout[email]=${email}`

      // const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123?checkout[email]=${email}`
      // window.location.href =
      //   'https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123'
      window.location.href = proCheckOutURL
    } else {
      toast.success(
        "To proceed further, please log in to your account. If you don't have an account yet, you can register one ",
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
      navigate('/login')
    }
  }

  const handlecvBuy = () => {
    if (localStorage.getItem('isLoggedIn')) {
      const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/c05df570-9e45-4edd-8249-39e66722adcf?checkout[email]=${email}`

      // const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123?checkout[email]=${email}`
      // window.location.href =
      //   'https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123'
      window.location.href = proCheckOutURL
    } else {
      toast.success(
        "To proceed further, please log in to your account. If you don't have an account yet, you can register one ",
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
      navigate('/login')
    }
  }

  const handleJobSeekerClick = () => {
    setIsJobSeeker(true)
  }
  const handleFreeClick = async () => {
    if (localStorage.getItem('isLoggedIn')) {
      navigate('/job-seeker')
    } else {
      navigate('/login')
    }
  }
  const handlePremiumClick = async () => {
    if (localStorage.getItem('isLoggedIn')) {
      navigate('/job-seeker')
    } else {
      navigate('/login')
    }
  }
  return (
    <div className="landing-page">
      <div className="content">
        <div className="header">
          <h1 className="main-heading">
            <span className="gradient-text">
              Free ATS Score & Analyzer with AI
            </span>
          </h1>
          <p className="sub-heading">
            Unlock the power of AI to enhance your recruitment and job search
            experience..
          </p>
        </div>
        <div className="buttons">
          <Link to="/job-seeker">
            <button className="job-seeker-btn">Job Seeker</button>
          </Link>
          <Link to="/recruiter">
            <button className="job-hirerer-btn">Recruiter</button>
          </Link>{' '}
        </div>

        <div className="header">
          <img
            src={require('../assets/recruiterdb.png')}
            className="reportClass"
          />
        </div>

        <div className="header">
          <h1 className="recruiter-heading">
            <span>Saving Recruiters Hours of Work</span>
          </h1>
          <p className="sub-heading">
            AI will automatically review your resume against job descriptions
            and give ATS scores
          </p>
        </div>
        <div className="header">
          <img
            src={require('../assets/coverletter.png')}
            className="reportClass"
          />
          <h1 className="cover-heading">
            <span>Free CoverLetter generator tool with AI</span>
          </h1>
        </div>
        <div className="buttons">
          <Link to="/job-seeker">
            <button className="job-seeker-btn">
              Generate Free Cover Letter{' '}
            </button>
          </Link>
        </div>
        <div className="header">
          <PricingContainer>
            <PricingCard>
              <Header>Basic Plan</Header>

              <Price>Free</Price>
              <PriceDetail></PriceDetail>

              <FeatureList>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>ATS Score</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Job Seeker Feature</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✗</FeatureIcon>
                  <FeatureText>Recruiter Feature</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✗</FeatureIcon>
                  <FeatureText>Unlimited Analysis</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Cover Letter Generator</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✗</FeatureIcon>
                  <FeatureText>Download Coverletter</FeatureText>
                </Feature>
              </FeatureList>
              <PurchaseButton onClick={handleFreeClick}>
                Get Started
              </PurchaseButton>
            </PricingCard>
            <PricingCard>
              <Header>Basic Plus</Header>
              <Price>$ 2.99</Price>
              <PriceDetail>per month</PriceDetail>
              <FeatureList>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>ATS Score</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Job Seeker Feature</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✗</FeatureIcon>
                  <FeatureText>Recruiter Feature</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Unlimited Analysis</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Cover Letter Generator</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Download Coverletter</FeatureText>
                </Feature>
              </FeatureList>
              <PurchaseButton onClick={handlecvBuy}>Get Started</PurchaseButton>
            </PricingCard>

            <PricingCard>
              <Header>Premium Plan</Header>
              <Price>$ 19.9</Price>
              <PriceDetail>per month</PriceDetail>
              <FeatureList>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>ATS Score</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Job Seeker Feature</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Recruiter Feature</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Unlimited Analysis</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Cover Letter Generator</FeatureText>
                </Feature>
                <Feature>
                  <FeatureIcon>✓</FeatureIcon>
                  <FeatureText>Download Coverletter</FeatureText>
                </Feature>
              </FeatureList>
              <PurchaseButton onClick={handleBuy}>Get Started</PurchaseButton>
            </PricingCard>
          </PricingContainer>
        </div>
      </div>
      <div className="video-bg">
        <video autoPlay loop muted>
          <source src={videobg} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-top: 50px;
  max-width: 1000px;
  padding: 40px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  /* Border */
  border: 0px solid #ff6e7f;
  @media (max-width: 768px) {
    display: block;
  }
`

const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: transparent;
  backdrop-filter: blur(45px);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid #ff6e7f;
`

const Header = styled.h2`
  font-size: 24px;
  color: #ff6e7f;
  margin-bottom: 20px;
`

const Price = styled.h1`
  font-size: 48px;
  color: #ff6e7f;
  margin-bottom: 10px;
`

const PriceDetail = styled.p`
  font-size: 16px;
  color: #ccc;
  margin-bottom: 30px;
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
`

const Feature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const FeatureIcon = styled.span`
  font-size: 20px;
  color: ${(props) => (props.children === '✓' ? '#ff6e7f' : '#555')};
  margin-right: 10px;
`

const FeatureText = styled.span`
  font-size: 16px;
  color: #ddd;
`

const PurchaseButton = styled.button`
  padding: 12px 24px;
  background-color: #ff6e7f;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e65c6f;
  }
`

export default LandingPage
