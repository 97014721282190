import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const PricingPage = () => {
  const navigate = useNavigate()

  const [rzp, setRzp] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSocial, setIsSocial] = useState(false)
  const [status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [enDate, setEnDate] = useState('')
  const [progress, setProgress] = useState(0)
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setName(userName)
      setEmail(userEmail)
      setIsSocial(userObject.isSocial)
      setStatus(userObject.status)
      setStartDate(userObject.startDate)
      setEnDate(userObject.enDate)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      console.log('++++++++++++++++ storedValue', storedValue)

      setLoggedIn(true)
    }
  }, [])

  useEffect(() => {
    // Manually initialize Lemon.js after the component has mounted
    window.createLemonSqueezy()
  }, [])
  const handleBuy = () => {
    if (localStorage.getItem('isLoggedIn')) {
      const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/748b2fcc-f31d-4628-b3fd-9723399bad3c?checkout[email]=${email}`

      // const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123?checkout[email]=${email}`
      // window.location.href =
      //   'https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123'
      window.location.href = proCheckOutURL
    } else {
      toast.success(
        "To proceed further, please log in to your account. If you don't have an account yet, you can register one ",
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
      navigate('/login')
    }
  }

  const handlecvBuy = () => {
    if (localStorage.getItem('isLoggedIn')) {
      //const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/c05df570-9e45-4edd-8249-39e66722adcf?checkout[email]=${email}`

      const proCheckOutURL = `https://resumeanalyser.lemonsqueezy.com/buy/da0e9be5-4492-42e9-9b06-63c61131d34c?checkout[email]=${email}`
      // window.location.href =
      //   'https://resumeanalyser.lemonsqueezy.com/buy/04fc94a7-0b9a-48cf-bc27-a2c063cb4123'
      window.location.href = proCheckOutURL
    } else {
      toast.success(
        "To proceed further, please log in to your account. If you don't have an account yet, you can register one ",
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
      navigate('/login')
    }
  }
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const handleFreeClick = async () => {
    if (localStorage.getItem('isLoggedIn')) {
      navigate('/job-seeker')
    } else {
      navigate('/login')
    }
  }
  const handleClick = async () => {
    if (localStorage.getItem('isLoggedIn')) {
      navigate('/job-seeker')
    } else {
      navigate('/login')
    }
    // const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    // if (!res) {
    //   alert('Razorpay SDK failed to load. Are you online?')
    //   return
    // }
    // const options = {
    //   key: 'rzp_test_wGbzJ0ArGHSHC5', // Your Razorpay API Key
    //   subscription_id: 'sub_NyTUvocrl0toyX', // Your Subscription ID (if applicable)
    //   name: 'Acme Corp.',
    //   description: 'Monthly Test Plan',
    //   image: '/your_logo.jpg', // URL for your logo
    //   handler: function (response) {
    //     // Handle the response from Razorpay
    //     alert(`Payment ID: ${response.razorpay_payment_id}`)
    //     alert(`Subscription ID: ${response.razorpay_subscription_id}`)
    //     alert(`Signature: ${response.razorpay_signature}`)
    //   },
    //   prefill: {
    //     name: 'Gaurav Kumar',
    //     email: 'gaurav.kumar@example.com',
    //     contact: '+919944092994',
    //   },
    //   notes: {
    //     note_key_1: 'Tea. Earl Grey. Hot',
    //     note_key_2: 'Make it so.',
    //   },
    //   theme: {
    //     color: '#F37254', // Custom theme color
    //   },
    // }
    // const paymentObject = new window.Razorpay(options)
    // paymentObject.open()
  }

  return (
    <PricingWrapper>
      <PricingContainer>
        <PricingCard>
          <Header>Basic Plan</Header>
          <Price>Free</Price>
          <PriceDetail>per month</PriceDetail>
          <FeatureList>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Job Seeker Feature</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✗</FeatureIcon>
              <FeatureText>Recruiter Feature</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✗</FeatureIcon>
              <FeatureText>Unlimited Analysis</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Cover Letter Generator</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✗</FeatureIcon>
              <FeatureText>Download Coverletter</FeatureText>
            </Feature>
          </FeatureList>
          <PurchaseButton onClick={handleFreeClick}>Get Started</PurchaseButton>
        </PricingCard>
        <PricingCard>
          <Header>Basic Plus</Header>
          <Price>$ 2.99</Price>
          <PriceDetail>per month</PriceDetail>
          <FeatureList>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Job Seeker Feature</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✗</FeatureIcon>
              <FeatureText>Recruiter Feature</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Unlimited Analysis</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Cover Letter Generator</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Download Coverletter</FeatureText>
            </Feature>
          </FeatureList>
          <PurchaseButton onClick={handlecvBuy}>Get Started</PurchaseButton>
        </PricingCard>
        <PricingCard>
          <Header>Premium Plan</Header>
          <Price>$ 19.99</Price>
          <PriceDetail>per month</PriceDetail>
          <FeatureList>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Job Seeker Feature</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Recruiter Feature</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Unlimited Analysis</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Cover Letter Generator</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>✓</FeatureIcon>
              <FeatureText>Download Coverletter</FeatureText>
            </Feature>
          </FeatureList>
          <PurchaseButton onClick={handleBuy}>Get Started</PurchaseButton>
        </PricingCard>
      </PricingContainer>
    </PricingWrapper>
  )
}

const PricingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #11121c;
  backdrop-filter: blur(10px);
  overflow: auto;
  padding-bottom: 8rem;
`

const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  max-width: 1000px;
  padding: 40px;
  background-color: #11121c;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  /* Border */
  border: 2px solid #ff6e7f;
  @media (max-width: 768px) {
    display: block;
  }
`

const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid #ff6e7f;
`

const Header = styled.h2`
  font-size: 24px;
  color: #ff6e7f;
  margin-bottom: 20px;
`

const Price = styled.h1`
  font-size: 48px;
  color: #ff6e7f;
  margin-bottom: 10px;
`

const PriceDetail = styled.p`
  font-size: 16px;
  color: #ccc;
  margin-bottom: 30px;
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
`

const Feature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const FeatureIcon = styled.span`
  font-size: 20px;
  color: ${(props) => (props.children === '✓' ? '#ff6e7f' : '#555')};
  margin-right: 10px;
`

const FeatureText = styled.span`
  font-size: 16px;
  color: #ddd;
`

const PurchaseButton = styled.button`
  padding: 12px 24px;
  background-color: #ff6e7f;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e65c6f;
  }
`

export default PricingPage
