export const header = {
  headers: {
    'x-api-key': '1c7ybaC55a9qMmvTkcfJU7JkBcdG7DRi9b2iE9Gu',
  },
}

export const multipartheader = {
  headers: {
    'x-api-key': '1c7ybaC55a9qMmvTkcfJU7JkBcdG7DRi9b2iE9Gu',
    'content-type': 'multipart/form-data',
  },
}
