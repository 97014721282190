import React from 'react'
import './register.css'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import GoogleIcon from '@mui/icons-material/Google'

const RegisterScreen = () => {
  const navigate = useNavigate()

  useEffect(() => {}, [])
  const googlelogin = useGoogleLogin({
    onNonOAuthError: () => {
      console.log('userInfo')
    },
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse)
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data)

      console.log(userInfo)
      handleGoogleSubmit(userInfo.name, userInfo.email)
    },
    onError: () => {
      console.log('userInfo')
    },
    flow: 'implicit', // implicit is the default
  })

  const handleGoogleSubmit = (name, email) => {
    setLoading(true)

    commonService
      .apiService('social', { name, email })
      .then(async (res) => {
        if (res.success == true) {
          window.localStorage.setItem('isLoggedIn', true)
          window.localStorage.setItem('token', res.token)
          window.localStorage.setItem('user', JSON.stringify(res.user))

          toast.success(res.message, {
            type: 'success',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
          navigate('/')

          window.location.reload()
        } else {
          localStorage.setItem('isLoggedIn', false)

          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        localStorage.setItem('isLoggedIn', false)

        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmit = (e) => {
    // Prevent the default submit and page reload
    e.preventDefault()

    setLoading(true)

    commonService
      .registerService({ name, email, password })
      .then(async (res) => {
        if (res.success == true) {
          // await setAuthAsyncStorage(res);
          toast.success(res.message, {
            type: 'success',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })

          navigate('/verify', { state: { email } })
        } else {
          toast.success(res.message, {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
      })
      .catch((err) => {
        toast.success('Something went wrong! please try again later!!', {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState()
  const [password, setPassword] = useState('')
  const [name, setName] = useState()

  return (
    <div className="login-screen">
      <div className="login-container">
        <h1 className="login-title">SignUp</h1>
        <div className="login-content">
          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <label htmlFor="username" className="input-label">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input-text"
              />
            </div>
            <div className="input-field">
              <label htmlFor="username" className="input-label">
                Email
              </label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-text"
              />
            </div>
            <div className="input-field">
              <label htmlFor="password" className="input-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-text"
              />
            </div>
            <button type="submit" className="login-btn">
              {loading && (
                <div className="loader">
                  <PropagateLoader size={10} color="#fff" />
                </div>
              )}
              {!loading && 'Register'}{' '}
            </button>
            <div className="forgot-password">
              <span>By signing up you agree to our </span>
              <Link to="/terms" className="forgot-password-link">
                Terms of Service
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="signup-prompt">
        <span>Already have an account?</span>
        <Link to="/login" className="signup-link">
          Log In
        </Link>
      </div>
    </div>
  )
}

export default RegisterScreen
