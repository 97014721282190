import axios from 'axios'
import APIKit from '../APIKit'
import { header, multipartheader } from '../header'

function CommonService(api, payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userEmail = userObject.email

      console.log('User email:', userEmail)

      // Add userEmail to payload
      payload.email = userEmail

      // Add userEmail to payload
      payload.token = window.localStorage.getItem('token')
    } catch (e) {
      console.log('failed===========' + e)
    }

    APIKit.post(api, payload, header)
      .then(async (response) => {
        try {
          console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status))
          console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          console.log('err===========', e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========', err)

        reject(err)
      })
  })
}

function CommonServiceGet(api, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      const userObject = JSON.parse(userJSON)
      const userEmail = userObject.email

      // Construct the request URL with query parameters
      const url = `${api}?email=${userEmail}&jobId=${id}`

      // Make the GET request
      APIKit.get(url, {
        headers: {
          'x-api-key': '1c7ybaC55a9qMmvTkcfJU7JkBcdG7DRi9b2iE9Gu',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          console.log('response status:', response.status)
          console.log('response data:', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.error('Error:', error)
          reject(error)
        })
    } catch (error) {
      console.error('Error:', error)
      reject(error)
    }
  })
}

function userdata(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userEmail = userObject.email

      console.log('User email:', userEmail)

      payload.append('token', window.localStorage.getItem('token'))
      payload.append('email', userEmail)
    } catch (e) {
      console.log('failed===========' + e)
    }
    console.log('User payload:', payload)

    APIKit.post('userdata', payload, header)
      .then(async (response) => {
        try {
          console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status))
          console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          console.log('err===========', e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========', err)

        reject(err)
      })
  })
}

function diagram(api, payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userEmail = userObject.email

      console.log('User email:', userEmail)

      payload.append('token', window.localStorage.getItem('token'))
      payload.append('email', userEmail)
    } catch (e) {
      console.log('failed===========' + e)
    }

    APIKit.post(api, payload, multipartheader)
      .then(async (response) => {
        try {
          console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status))
          console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========' + err)

        reject(err)
      })
  })
}

function loginService(payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))

  return new Promise(async (resolve, reject) => {
    APIKit.post('login', payload, header)
      .then(async (response) => {
        try {
          console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status))
          console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========' + err)

        reject(err)
      })
  })
}
function registerService(payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))

  return new Promise(async (resolve, reject) => {
    APIKit.post('register', payload, header)
      .then(async (response) => {
        try {
          console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status))
          console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========' + err)

        reject(err)
      })
  })
}

function apiService(api, payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))

  return new Promise(async (resolve, reject) => {
    APIKit.post(api, payload, header)
      .then(async (response) => {
        try {
          console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status))
          console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========' + err)

        reject(err)
      })
  })
}

export const commonService = {
  CommonService,
  CommonServiceGet,
  loginService,
  registerService,
  apiService,
  diagram,
  userdata,
}
