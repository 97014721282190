import React from 'react'
import './common.css' // Assuming you have a CSS file for styling

function Privacy() {
  return (
    <div className="dark-theme">
      <h1>Privacy Policy</h1>
      <p>
        At Resume Analyser, we prioritize transparency, accessibility, sanity,
        and usability in the collection and management of user data. This
        document forms an integral part of Resume Analyser's Terms of Service.
        By using resumeanalyser.com (the “Website”), you are consenting to the
        terms outlined in this Privacy Policy as well as the Terms of Service.
        We encourage you to read the Terms of Service in full for comprehensive
        definitions and contact information.
      </p>
      <h2>Data Collected</h2>
      <p>
        We gather anonymous data from every visitor to our Website to monitor
        traffic and address any bugs. This data includes information such as web
        requests, the data transmitted in response to these requests, Internet
        Protocol (IP) addresses, browser types, browser languages, and request
        timestamps.
      </p>
      <p>
        We may request you to log in and provide specific personal information,
        such as your name and email address. We utilize cookies to store session
        information for your convenience. While you can block or delete cookies,
        this may necessitate manual entry of your email and password with each
        login.
      </p>
      <p>
        You have the ability to view, modify, or delete data associated with
        your profile. If you opt to delete your account, please contact us at
        support@resumeanalyser.com, and we will promptly address your request.
      </p>
      <p>
        Minors and children are advised not to use Resume Analyser. By using the
        Website, you affirm that you possess the legal capacity to enter into
        binding agreements.
      </p>
      <h2>Collection and Use of Personal Information</h2>
      <p>
        Personal information is data that can be used to uniquely identify or
        contact a specific individual.
      </p>
      <p>
        You may be prompted to provide personal information when you contact us,
        fill out our contact form, or subscribe to our newsletter. While the
        provision of requested information is not mandatory, not doing so may
        impact our ability to provide products, services, or respond to your
        inquiries.
      </p>
      <p>
        Here are examples of the types of personal information that we collect:
      </p>
      <ul>
        <li>
          When you use our software, we gather further details such as crash
          reports, operating system information, application versions, user
          language, and login status.
        </li>
      </ul>
      <h2>How we use your personal information</h2>
      <p>
        The personal information we collect allows us to keep you up to date on
        our latest product announcements, software updates, and services. You
        may at any time opt out of receiving such communications by contacting
        us. In particular, we only send you our newsletter with your prior
        consent, and you can opt out of receiving the newsletter anytime by
        clicking the unsubscribe link we include in each newsletter, or by
        contacting us.
      </p>
      <p>
        We also use the personal information we collect to help us create,
        develop, deliver, protect, and improve our products, services, content,
        and customer communications.
      </p>
      <p>
        We may use your personal information to send important notices, such as
        communications about changes to our terms, conditions, and policies. As
        this information is important to your interaction with Resume Analyser,
        you may not opt out of receiving these communications.
      </p>
      <p>
        We may also use personal information for internal purposes such as
        auditing, data analysis, and research to improve our products, services,
        and customer communications.
      </p>
      <p>
        We employ industry standard techniques to protect against unauthorized
        access of data about you that we store, including personal information.
      </p>
      <p>
        We do not share personal information you have provided to us without
        your consent, unless:
      </p>
      <ul>
        <li>doing so is appropriate to carry out your own request;</li>
        <li>
          we believe it's needed to enforce our Terms of Service, or that is
          legally required;
        </li>
        <li>
          we believe it's needed to detect, prevent or address fraud, security
          or technical issues;
        </li>
        <li>
          otherwise protect our property, legal rights, or that of others.
        </li>
      </ul>
      <h2>Collection and Use of Non-Personal Information</h2>
      <p>
        Non-personal information is data that cannot, on its own, be used to
        uniquely identify a specific individual. We may collect, use, transfer,
        and disclose non-personal information for any purpose. We may combine
        personal and non-personal information for certain purposes; this data
        will then be treated as personal information for as long as it remains
        combined. Conversely, aggregated information, i.e. data that might have
        originated from personal information, but that has been processed in
        such a way as to not allow personal identification, is treated as
        non-personal information.
      </p>
      <h2>Cookies and Other Technologies</h2>
      <p>
        Our website, email communications and advertisements may use “cookies”
        and other technologies such as “pixel tags” and “click-through URLs”.
      </p>
      <p>
        We use the information we collect in this manner to better understand
        our users’ interaction with our website and to optimize the user
        experience. You can disable cookies in your browser settings, but please
        note that certain features on our website may not be available as a
        result.
      </p>
      <p>
        We may contact you via email or other means, including promotional
        emails related to Resume Analyser or third parties we believe you might
        be interested in, as well as communications regarding your Website
        usage. You can adjust your email notification preferences through the
        provided links in email footers or by editing your profile preferences.
      </p>
      <h2>Sharing of Data</h2>
      <p>
        We don’t share personal information with anyone outside of Resume
        Analyser, except for the few exceptions below.
      </p>
      <p>
        We work together with other companies who provide information processing
        services. We only share personal information with these companies if you
        have agreed to the transfer, or if it is permitted by data protection
        law. The information we share is limited to the data necessary for the
        third parties to provide their services. We use these companies for the
        following services: hosting of our website and support portal, providing
        our help desk software, sending out newsletters, analyzing our website
        traffic, hosting our cloud services, manage our app beta testing, and
        processing our app crash reports. These companies are obligated to
        protect your information in accordance with data protection law and
        provide the necessary safeguards if they are outside of the EU. The
        companies are bound by our instructions, and are not allowed to use the
        shared data for any other purpose.
      </p>
      <p>
        We also share personal information if disclosure of such information is
        reasonably necessary to satisfy any applicable law, regulation, legal
        process or enforceable governmental request; to enforce applicable Terms
        of Service, including investigation of potential violations thereof; to
        detect, prevent, or otherwise address fraud or security issues; and to
        protect against harm to the rights, property or safety of Resume
        Analyser, its users or the public as required or permitted by law.
      </p>
      <h2>Protection of Personal Information</h2>
      <p>
        We implement security measures to guard against unauthorized data
        access, alteration, disclosure, or destruction. These measures include
        internal data reviews, encryption, and physical security safeguards.
      </p>
      <p>
        Access to personal information is restricted within Resume Analyser to
        employees who require it for service deployment and maintenance. These
        individuals are bound by confidentiality agreements.
      </p>
      <p>
        While we use encryption like Transport Layer Security (TLS) for data
        transmission, no method of data transmission or storage is entirely
        secure. Consequently, we cannot guarantee the security of information
        you transmit to us.
      </p>
      <h2>Limits of Use of Your Google Information</h2>
      <p>
        Resume Analyser relies on certain Google services, including the Google
        API, where we allow you to connect your Google account using OAth
        authentication, a secure method to give Resume Analyser access to your
        Google account data without letting it know your password. We would like
        to clarify that, despite any statements to the contrary in our Privacy
        Policy, if you give Resume Analyser permission to access your Google
        account data, we will only use this data according to the following
        restrictions:
      </p>
      <ul>
        <li>
          Our usage of access to read, write, modify, or control settings will
          be limited to only retrieving and providing information for the
          specific features that you grant and use on Resume Analyser.
        </li>
        <li>
          We will not transfer your data to anyone else unless it is necessary
          to improve the features of our application provided to you, comply
          with applicable law, or participate in a merger, acquisition, or sale
          of assets.
        </li>
        <li>
          We will never use or distribute your data for the purpose of serving
          advertising, including retargeting, personalized, or interest-based
          advertising.
        </li>
        <li>
          We will not allow humans to access your data unless one of the
          following conditions is met: we have your explicit permission to
          access specific messages, we need to investigate a security issue, we
          are required by law, or the data has been aggregated and anonymized
          for our own internal operations purposes.
        </li>
      </ul>
      <h2>Changes to the Privacy Policy</h2>
      <p>
        We may amend this Privacy Policy from time to time. Use of information
        we collect now is subject to the Privacy Policy in effect at the time
        such information is used. If we make major changes in the way we collect
        or use information, we will notify you by posting an announcement on the
        Website or sending you an email. A user is bound by any changes to the
        Privacy Policy when he or she uses the Services after such changes have
        been first posted.
      </p>
      <p>
        Should you have any question or concern, please write to
        support@resumeanalyser.com.
      </p>
    </div>
  )
}

export default Privacy
