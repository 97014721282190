import React from 'react'
import './LoginScreen.css'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import GoogleIcon from '@mui/icons-material/Google'
import OTPInput, { ResendOTP } from 'otp-input-react'

const Verification = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { email } = state // Read values passed on state

  useEffect(() => {}, [])

  const handleSubmit = (e) => {
    // Prevent the default submit and page reload
    e.preventDefault()
    if (code.length == 6 && email) {
      setLoading(true)

      commonService
        .apiService('verify', { email: email, code })
        .then(async (res) => {
          setCode('')
          if (res.success == true) {
            window.localStorage.setItem('isLoggedIn', true)
            window.localStorage.setItem('token', res.token)
            window.localStorage.setItem('user', JSON.stringify(res.user))
            toast.success(res.message, {
              type: 'success',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            navigate('/')

            window.location.reload()
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
          }
        })
        .catch((err) => {
          toast.success('Something went wrong! please try again later!!', {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      toast.success('Invalid Request', {
        type: 'error',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      })
    }
  }

  const resendCode = (e) => {
    // Prevent the default submit and page reload
    e.preventDefault()
    if (email) {
      setLoading(true)

      commonService
        .apiService('resend', { email })
        .then(async (res) => {
          setCode('')
          if (res.success == true) {
            // await setAuthAsyncStorage(res);
            toast.success(res.message, {
              type: 'success',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
          } else {
            toast.success(res.message, {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
          }
        })
        .catch((err) => {
          toast.success('Something went wrong! please try again later!!', {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      toast.success('Invalid Request', {
        type: 'error',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      })
    }
  }
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')

  const [password, setPassword] = useState('')

  return (
    <div className="login-wrapper">
      <div className="login-screen">
        <div className="login-container">
          <h1 className="login-title">Verify Your Account</h1>
          <div className="login-content">
            <div>Please enter the code that you received in your email</div>
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <OTPInput
                  value={code}
                  onChange={setCode}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
              </div>

              <button type="submit" className="login-btn">
                {loading && (
                  <div className="loader">
                    <PropagateLoader size={10} color="#fff" />
                  </div>
                )}
                {!loading && 'Submit'}
              </button>
            </form>
          </div>
        </div>
        <div className="signup-prompt">
          <span> Not yet recieved code?</span>
          <a href="#" onClick={resendCode} className="signup-link">
            Resend
          </a>
        </div>
      </div>
    </div>
  )
}

export default Verification
