import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import { useNavigate } from 'react-router-dom'
const logoImage = require('../assets/logo.png')
const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)
  const [name, setName] = useState('User')

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('isLoggedIn')
    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      setName(userName)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      setLoggedIn(true)
    }
  }, [])

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }
  const onLogout = () => {
    window.localStorage.setItem('isLoggedIn', false)
    window.localStorage.setItem('token', '')
    window.localStorage.setItem('user', null)
    navigate('/')

    window.location.reload()
  }
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img src={logoImage} className="logo" alt="Resume Analyzer" />
        </Link>
      </div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/job-seeker" className="nav-link">
            Job Seeker
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/recruiter" className="nav-link">
            Recruiter
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/pricing" className="nav-link">
            Pricing
          </Link>
        </li>
        {loggedIn ? (
          <li className="nav-item account-menu">
            <div className="account-toggle">
              {name}
              <span className="dropdown-icon">&#9662;</span>{' '}
            </div>
            <div className="dropdown-menu">
              <Link to="/myaccount" className="dropdown-item">
                My Account
              </Link>
              <a href="#" onClick={onLogout} className="dropdown-item">
                Logout
              </a>
            </div>
          </li>
        ) : (
          <li className="nav-item">
            <Link to="/login" className="nav-link">
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Navbar
