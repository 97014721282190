import React, { useState } from 'react'
import Navbar from './Navbar'
import JobSeekerDashboard from './JobSeekerDashboard'
import RecruiterDashboard from './RecruiterDashboard'
import LandingPage from './LandingPage'
import LoginScreen from './LoginScreen'
import RegisterScreen from './RegisterScreen'
import Footer from './Footer'
import Myaccount from './Myaccount'
import ForgotPassword from './ForgotPassword'
import PricingPage from './PricingPage'
import Verification from './Verification'
import Contactpage from './contact'
import Terms from './Terms'
import Privacy from './privacy'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  HashRouter,
} from 'react-router-dom'
const AppLayout = () => {
  const location = useLocation()
  const isLandingPage = location.pathname === '/'
  const isLoginPage = location.pathname === '/login'
  const isregister = location.pathname === '/register'

  return (
    <>
      {!isLandingPage && !isLoginPage && !isregister && <Navbar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/job-seeker" element={<JobSeekerDashboard />} />
        <Route path="/recruiter/:jobId" element={<RecruiterDashboard />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route
          path="/recruiter"
          element={<Navigate to="/recruiter/defaultJobId" />}
        />
        <Route path="/myaccount" element={<Myaccount />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/verify" element={<Verification />} />
        <Route path="/contact" element={<Contactpage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      {!isLandingPage && !isLoginPage && !isregister && <Footer />}
    </>
  )
}
export default AppLayout
