import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'

const contact = () => {
  return (
    <div>
      <Wrapper>
        <Header>Contact</Header>
        <Section>
          <SectionTitle>Contact Information</SectionTitle>
          <UserInfo>
            <Label>support@resumeanalyser.com</Label>
            <Value></Value>
            <Label>eversolutions24@gmail.com</Label>
            <Value></Value>
          </UserInfo>
        </Section>
      </Wrapper>

      <div style={styles.container}>
        <h2 style={styles.heading}>Interested in Similar Projects?</h2>
        <p style={styles.paragraph}>
          Hello there! If you've stumbled upon our platform and found our
          services intriguing, you might be wondering if we're available for
          similar projects tailored to your needs. The answer is yes! We're
          ready and eager to take on new challenges and work with individuals or
          businesses who seek solutions similar to what we offer.
        </p>
        <p style={styles.paragraph}>
          Whether you're looking for custom software development, SaaS
          solutions, or any other related services, we're here to help. Simply
          drop us an email at{' '}
          <a href="mailto:eversolutions24@gmail.com" style={styles.link}>
            eversolutions24@gmail.com
          </a>{' '}
          with details about your project requirements, and we'll get back to
          you promptly to discuss how we can assist you.
        </p>
        <p style={styles.paragraph}>
          Don't hesitate to reach out! We're excited about the opportunity to
          collaborate and bring your ideas to life.
        </p>
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Header = styled.h1`
  font-size: 24px;
  color: #ff6e7f;
  margin-bottom: 20px;
`

const Section = styled.div`
  margin-bottom: 30px;
  margin-top: 60px;
`

const SectionTitle = styled.h2`
  font-size: 18px;
  color: #ff6e7f;
  margin-bottom: 10px;
`

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

const SubscriptionInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

const Label = styled.span`
  font-weight: bold;
`

const Value = styled.span`
  color: #666;
`

const PasswordForm = styled.form`
  display: grid;
  grid-gap: 10px;
`

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`

const Button = styled.button`
  padding: 8px 16px;
  background-color: #ff6e7f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e65c6f;
  }
`
const styles = {
  container: {
    backgroundColor: '#222',
    color: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    margin: '0 auto',
    textAlign: 'center',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '15px',
  },
  link: {
    color: '#00a8ff',
    textDecoration: 'none',
  },
}
export default contact
